export default class OrderNotification {
    private readonly _id: number;
    private readonly _createdAt: number;
    private readonly _orderId: number;
    private readonly _orderCrmId: string;
    private readonly _title: string;
    private readonly _description: string;
    private readonly _completedUserEmail: string | null;
    private _isNew: boolean;

    constructor(id: number, createdAt: number, orderId: number, orderCrmId: string, title: string, description: string, userCompletedEmail: string | null, isNew: boolean) {
        this._id = id;
        this._createdAt = createdAt;
        this._orderId = orderId;
        this._orderCrmId = orderCrmId;
        this._title = title;
        this._description = description;
        this._completedUserEmail = userCompletedEmail;
        this._isNew = isNew;
    }

    get id(): number {
        return this._id;
    }

    get createdAt(): number {
        return this._createdAt;
    }

    get orderId(): number {
        return this._orderId;
    }

    get orderCrmId(): string {
        return this._orderCrmId;
    }

    get title(): string {
        return this._title;
    }

    get description(): string {
        return this._description;
    }

    get completedUserEmail(): string | null {
        return this._completedUserEmail;
    }

    get isNew(): boolean {
        return this._isNew;
    }

    set isNew(value: boolean) {
        this._isNew = value;
    }
}