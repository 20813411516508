import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":_vm.color,"dark":"","dense":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,{staticClass:"ml-0 px-4",staticStyle:{"width":"180px"}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Nebo.Travel")])]),(_vm.isAviaPermitted && _vm.isToursPermitted)?_c(VTabs,{attrs:{"value":_vm.tab}},[(_vm.isAviaPermitted)?_c(VTab,{key:"avia",attrs:{"tab-value":"avia"},on:{"click":_vm.onAviaClick}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-airplane")]),_vm._v("Авиабилеты")],1):_vm._e(),(_vm.isToursPermitted)?_c(VTab,{key:"tours",attrs:{"tab-value":"tours"},on:{"click":_vm.onToursClick}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-palm-tree")]),_vm._v("Туры")],1):_vm._e()],1):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleTheme}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-theme-light-dark")])],1),(_vm.isAdmin)?_c(VBtn,{staticClass:"ml-2",attrs:{"icon":"","to":{ name: 'orderNotifications' }}},[_c(VBadge,{attrs:{"content":_vm.notificationsCount,"value":_vm.notificationsCount,"color":"red","overlap":""}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-bell")])],1)],1):_vm._e(),_c(VBtn,{staticClass:"ml-2",attrs:{"icon":""},on:{"click":_vm.logout}},[_c(VIcon,[_vm._v("mdi-power")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }