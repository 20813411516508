import {AxiosInstance} from "axios";
import axiosInstance from "@/api";
import OrderNotification from "@/models/OrderNotification";

export class OrderNotificationService {
    private readonly _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getNewCount(): Promise<number> {
        const response = await this._api.get('/admin/notificationscount');

        return response.data.count || 0;
    }

    async getList(): Promise<OrderNotification[]> {
        const response = await this._api.get('/admin/notifications');

        return response.data.map((data: any) => {
            return new OrderNotification(
                data.id,
                data.createdAt,
                data.orderId,
                data.orderCrmId,
                data.title,
                data.description,
                data.completedUserEmail,
                data.isNew,
            );
        });
    }

    async complete(notificationId: number): Promise<OrderNotification> {
        const response = await this._api.put('/admin/notifications/' + notificationId.toString() + '/complete');

        const data = response.data;

        return new OrderNotification(
            data.id,
            data.createdAt,
            data.orderId,
            data.orderCrmId,
            data.title,
            data.description,
            data.completedUserEmail,
            data.isNew,
        );
    }

    async completeAll(): Promise<void> {
        await this._api.put('/admin/notifications/complete');
    }
}

export default new OrderNotificationService(axiosInstance);