import { ActionTree } from 'vuex';
import { AuthState } from '@/store/auth/types';
import { RootState } from '@/store/types';
import authService from '@/services/AuthService'
import router from '@/router'


export const actions: ActionTree<AuthState, RootState> = {
    login({dispatch, commit}, {email, password}) {
        authService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push({name: 'index'});
                },
                error => {
                    commit('loginFailure', error);
                }
            );
    },
    logout({ commit }) {
        authService.logout();
        commit('logout');
    }
};