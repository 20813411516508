<template>
  <v-list dense>
    <v-list-item :to="{ name: 'tourOrdersListPage' }" v-if="isOrdersPermitted">
      <v-list-item-action
        ><v-icon>mdi-ticket-confirmation-outline</v-icon></v-list-item-action
      >
      <v-list-item-content
        ><v-list-item-title>Заказы</v-list-item-title></v-list-item-content
      >
    </v-list-item>
    <v-list-item
      :to="{ name: 'tourPackagesListPage' }"
      v-if="isOrdersPermitted"
    >
      <v-list-item-action
        ><v-icon>mdi-progress-pencil</v-icon></v-list-item-action
      >
      <v-list-item-content
        ><v-list-item-title
          >Незавершенные заказы</v-list-item-title
        ></v-list-item-content
      >
    </v-list-item>
    <v-list-item :to="{ name: 'hotelsListPage' }" v-if="isHotelsListPermitted">
      <v-list-item-action
        ><v-icon>mdi-office-building-marker</v-icon></v-list-item-action
      >
      <v-list-item-content
        ><v-list-item-title>Отели</v-list-item-title></v-list-item-content
      >
    </v-list-item>
    <v-list-item :to="{ name: 'resortsListPage' }" v-if="isResortsPermitted">
      <v-list-item-action><v-icon>mdi-palm-tree</v-icon></v-list-item-action>
      <v-list-item-content
        ><v-list-item-title>Курорты</v-list-item-title></v-list-item-content
      >
    </v-list-item>
    <v-list-item
      :to="{ name: 'flightSurchargesListPage' }"
      v-if="isFlightSurchargesPermitted"
    >
      <v-list-item-action
        ><v-icon>mdi-barrel-outline</v-icon></v-list-item-action
      >
      <v-list-item-content
        ><v-list-item-title
          >Топливные сборы</v-list-item-title
        ></v-list-item-content
      >
    </v-list-item>
    <v-list-group
      no-action
      prepend-icon="mdi-book-alphabet"
      v-if="isReferencesPermitted"
    >
      <template v-slot:activator>
        <v-list-item-title>Справочники</v-list-item-title>
      </template>
      <v-list-item :to="{ name: 'partnerHotelReferencesListPage' }" link>
        <v-list-item-title>Отели</v-list-item-title>
        <v-list-item-icon
          ><v-icon>mdi-office-building-marker</v-icon></v-list-item-icon
        >
      </v-list-item>
      <v-list-item :to="{ name: 'partnerBoardBasisReferencesListPage' }" link>
        <v-list-item-title>Типы питания</v-list-item-title>
        <v-list-item-icon><v-icon>mdi-food</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item :to="{ name: 'partnerResortReferencesListPage' }" link>
        <v-list-item-title>Курорты</v-list-item-title>
        <v-list-item-icon><v-icon>mdi-palm-tree</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item :to="{ name: 'partnerTourReferencesListPage' }" link>
        <v-list-item-title>Группы туров</v-list-item-title>
        <v-list-item-icon
          ><v-icon>mdi-format-list-group</v-icon></v-list-item-icon
        >
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import authService from "@/services/AuthService";

@Component({
  components: {},
})
export default class ToursSidebar extends Vue {
  get isOrdersPermitted(): boolean {
    return authService.hasRole("ROLE_TOURS_ORDERS");
  }

  get isHotelsListPermitted(): boolean {
    return authService.hasRole("ROLE_TOURS_HOTELS_LIST");
  }

  get isResortsPermitted(): boolean {
    return authService.hasRole("ROLE_TOURS_RESORTS");
  }

  get isFlightSurchargesPermitted(): boolean {
    return authService.hasRole("ROLE_TOURS_FLIGHT_SURCHARGES");
  }

  get isReferencesPermitted(): boolean {
    return authService.hasRole("ROLE_TOURS_REFERENCES");
  }
}
</script>

<style lang="less" scoped></style>
