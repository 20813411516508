import { MutationTree } from 'vuex';
import { AuthState } from '@/store/auth/types';
import AdminUser from "@/models/AdminUser";

export const mutations: MutationTree<AuthState> = {
    loginSuccess(state: AuthState, user: AdminUser) {
        state.user = user;
    },
    loginFailure(state: AuthState) {
        state.user = null;
    },
    logout(state: AuthState) {
        state.user = null;
    }
};