import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import vuetify from '@/plugins/vuetify';

Vue.config.productionTip = false;

// Moment
const moment = require('moment');
require('moment/locale/ru');
Vue.use(require('vue-moment'), {
    moment
});

//VuetifyDialog
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.use(VuetifyDialog, {
    context: {
        vuetify
    },
    confirm: {
        actions: {
            false: 'Отмена',
            true: {
                text: 'Подтвердить',
                color: 'primary'
            }
        },
        icon: false
    },
    error: {
        actions: {
            false: 'Отмена',
            true: {
                text: 'Подтвердить',
                color: 'error'
            }
        },
        icon: false
    },
    warning: {
        actions: {
            false: 'Отмена',
            true: {
                text: 'Подтвердить',
                color: 'warning'
            }
        },
        icon: false
    },
    toast: {
        position: 'top',
    },
    prompt: {
        actions: [
            {text: 'Подтвердить', color: 'primary'},
        ]
    },
});

//Api
import {AxiosRequestConfig, AxiosResponse} from "axios";
import axiosInstance from "@/api";
axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    if (!token) {
        return config;
    }

    config.headers.Authorization = "Bearer " + token;

    return config;
});

axiosInstance.interceptors.response.use(function (response: AxiosResponse) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        store.dispatch('auth/logout').then(() => router.push({name: 'login'}));
    }
    return Promise.reject(error);
});


//Filters
Vue.filter('formatPrice', function (value: number): string {
    if (!value) {
        return '0';
    }

    let fraction = '';
    let strValue = value.toString();
    if (strValue.indexOf('.') >= 0) {
        const exploded = strValue.split('.');
        strValue = exploded[0];
        fraction = exploded[1];
    }

    let result = strValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (fraction !== "") {
        result += ',' + fraction;
    }

    return result;
});

//TIPTAP
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
});

//VUE-META
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
});

//Leaflet
import init from './leaflet';
init();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
