<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :clipped="true" app>
      <ToursSidebar
        :drawer="drawer"
        @drawerChange="drawer = $event"
        v-if="isTours"
      />
      <Sidebar :drawer="drawer" @drawerChange="drawer = $event" v-else />
    </v-navigation-drawer>
    <Navbar @drawerChange="drawer = $event" />
    <v-main>
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import Navbar from "./Navbar.vue";
import Sidebar from "./Sidebar.vue";
import ToursSidebar from "@/views/layout/ToursSidebar.vue";

@Component({
  components: {
    ToursSidebar,
    Navbar,
    Sidebar,
  },
})
export default class DefaultLayout extends Vue {
  drawer = true;

  get isTours() {
    return !!this.$route.meta.isTours;
  }
}
</script>
