import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex';
import {RootState} from "@/store/types";
import {auth} from '@/store/auth';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    modules: {
        auth,
    },
    state: {
        version: '1.0.0' // a simple property
    },
    mutations: {

    },
    actions: {

    }
};

export default new Vuex.Store<RootState>(store);