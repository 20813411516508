import AdminUser from "@/models/AdminUser";
import {AxiosInstance} from "axios";
import axiosInstance from "@/api";
let jwtDecode = require('jwt-decode');

export class AuthService {
    private _api: AxiosInstance;

    constructor (api: AxiosInstance) {
        this._api = api;
    }

    public getUser(): AdminUser|null {
        const token = localStorage.getItem('token');
        if (!token) {
            return null;
        }

        let tokenData = jwtDecode(token);
        if (!tokenData.username) {
            return null
        }

        return new AdminUser(token, tokenData.username, tokenData.roles);
    }

    public hasRole(role: string): boolean {
        const user = this.getUser();
        if (!user) {
            return false;
        }

        return user.roles.indexOf(role) !== -1;
    }

    public async login(email: String, password: String): Promise<AdminUser> {
        const self = this;
        return this._api.post('/private/login_check', {
            email: email,
            password: password
        }).then((response) => {
            if (!response.data.token) {
                self.logout();
                return Promise.reject("")
            }

            localStorage.setItem('token', response.data.token);

            const user = self.getUser();
            if (!user) {
                self.logout();
                return Promise.reject("")
            }

            return user;
        });
    }

    public logout(): void {
        localStorage.removeItem('token');
    }
}

export default new AuthService(axiosInstance);