import Vue from 'vue';
import Vuetify, { VIcon, VSnackbar, VBtn, VSimpleTable } from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
    directives: {
        Ripple,
    },
    components: {
        // the components which Toast.vue used
        VIcon,
        VSnackbar,
        VBtn,
        VSimpleTable,
    }
});

const tourTheme = {
    theme: {
        themes: {
            light: {
                primary: '#673ab7',
            },
        },
    },
};

// Locales
import ru from 'vuetify/src/locale/ru';

const themesParams = window.location.pathname.indexOf('/tours') === 0 ? tourTheme : {};

export default new Vuetify({
    lang: {
        locales: {ru},
        current: 'ru'
    },
    ...themesParams,
});