import Vue from "vue";
import Router, { Route } from "vue-router";
import store from "./store/index";

Vue.use(Router);

function authGuard(to: Route, from: Route, next: (url?: string) => void) {
  const isAuthorized = store.getters["auth/isAuthorized"];

  if (isAuthorized || to.meta.allowAnonymous) {
    next();
  } else {
    next("/login"); // go to '/login';
  }
}

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      allowAnonymous: true,
      layout: "LoginLayout",
    },
    beforeEnter: authGuard,
    component: () =>
      import(/* webpackChunkName: "index" */ "./views/pages/auth/Login.vue"),
  },
  {
    path: "/orders",
    name: "ordersList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "./views/pages/orders/OrdersListPage.vue"
      ),
  },
  {
    path: "/orders/compact",
    name: "ordersListCompact",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "./views/pages/orders/OrdersCompactListPage.vue"
      ),
  },
  {
    path: "/orders/:orderId",
    name: "orderDetails",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "./views/pages/orders/OrderDetailsPage.vue"
      ),
  },
  {
    path: "/extrachargetables",
    name: "extraChargeTablesList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "extracharge" */ "./views/pages/extra-charge/tables/ExtraChargeTablesListPage.vue"
      ),
  },
  {
    path: "/extrachargetables/:extraChargeTableId",
    name: "editExtraChargeTable",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "extracharge" */ "./views/pages/extra-charge/tables/EditExtraChargeTablePage.vue"
      ),
  },
  {
    path: "/regularextrachargetables",
    name: "regularExtraChargeTablesList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "regularextracharge" */ "./views/pages/extra-charge/regular-tables/RegularExtraChargeTablesListPage.vue"
      ),
  },
  {
    path: "/regularextrachargetables/:regularExtraChargeTableId",
    name: "editRegularExtraChargeTable",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "regularextracharge" */ "./views/pages/extra-charge/regular-tables/EditRegularExtraChargeTablePage.vue"
      ),
  },
  {
    path: "/extracharges",
    name: "extraChargesList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "extracharge" */ "./views/pages/extra-charge/ExtraChargesListPage.vue"
      ),
  },
  {
    path: "/extracharges/:extraChargeId",
    name: "editExtraCharge",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "extracharge" */ "./views/pages/extra-charge/EditExtraChargePage.vue"
      ),
  },
  {
    path: "/availability-settings/pairs",
    name: "pairSettingsListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "availabilitysettings" */ "./views/pages/availability-settings/PairSettingsListPage.vue"
      ),
  },
  {
    path: "/availability-settings/providers",
    name: "providerSettingsListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "availabilitysettings" */ "./views/pages/availability-settings/ProviderSettingsListPage.vue"
      ),
  },
  {
    path: "/availability-settings/clients",
    name: "clientSettingsListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "availabilitysettings" */ "./views/pages/availability-settings/ClientSettingsListPage.vue"
      ),
  },
  {
    path: "/insurance/medical/policies",
    name: "medicalInsuranceListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "medicalinsurance" */ "./views/pages/insurance/medical/MedicalInsuranceListPage.vue"
      ),
  },
  {
    path: "/insurance/baggage/policies",
    name: "baggageInsuranceListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "baggageinsurance" */ "./views/pages/insurance/baggage/BaggageInsuranceListPage.vue"
      ),
  },
  {
    path: "/insurance/trip-cancel/policies",
    name: "tripCancelInsuranceListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tripcancelinsurance" */ "./views/pages/insurance/trip_cancel/TripCancelInsuranceListPage.vue"
      ),
  },
  {
    path: "/manual-flight-groups",
    name: "manualFlightGroupsList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "manualflights" */ "./views/pages/manual-flights/ManualFlightGroupsListPage.vue"
      ),
  },
  {
    path: "/manual-flight-groups/:groupId",
    name: "editManualFlightGroup",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "manualflights" */ "./views/pages/manual-flights/EditManualFlightGroupPage.vue"
      ),
  },
  {
    path: "/flightfilters",
    name: "flightFiltersList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "flightfilters" */ "./views/pages/flight-filters/FlightFiltersListPage.vue"
      ),
  },
  {
    path: "/flightfilters/:flightFilterId",
    name: "editFlightFilter",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "flightfilters" */ "./views/pages/flight-filters/EditFlightFilterPage.vue"
      ),
  },
  {
    path: "/searchfilters",
    name: "searchFiltersList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "searchfilters" */ "./views/pages/search-filters/SearchFiltersListPage.vue"
      ),
  },
  {
    path: "/searchfilters/:filterId",
    name: "editSearchFilter",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "searchfilters" */ "./views/pages/search-filters/EditSearchFilterPage.vue"
      ),
  },
  {
    path: "/baggageallowances",
    name: "baggageAllowancesList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "baggageallowances" */ "./views/pages/baggage-allowance/BaggageAllowancesListPage.vue"
      ),
  },
  {
    path: "/baggageallowances/:baggageAllowanceId",
    name: "editBaggageAllowance",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "baggageallowances" */ "./views/pages/baggage-allowance/EditBaggageAllowancePage.vue"
      ),
  },
  {
    path: "/chartersettings",
    name: "charterSettingsList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "chartersettings" */ "./views/pages/charter-setting/CharterSettingsListPage.vue"
      ),
  },
  {
    path: "/chartersettings/:charterSettingId",
    name: "editCharterSetting",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "chartersettings" */ "./views/pages/charter-setting/EditCharterSettingPage.vue"
      ),
  },
  {
    path: "/tariffdescriptions",
    name: "tariffDescriptionsList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tariffdescription" */ "./views/pages/tariff-description/TariffDescriptionsListPage.vue"
      ),
  },
  {
    path: "/tariffdescriptions/:tariffDescriptionId",
    name: "editTariffDescription",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tariffdescription" */ "./views/pages/tariff-description/EditTariffDescriptionPage.vue"
      ),
  },
  {
    path: "/portbilet/settings",
    name: "portbiletSettingsList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "portbilet" */ "./views/pages/portbilet/PortbiletSettingsListPage.vue"
      ),
  },
  {
    path: "/portbilet/settings/:settingsId",
    name: "editPortbiletSettings",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "portbilet" */ "./views/pages/portbilet/PortbiletSettingsEditPage.vue"
      ),
  },
  {
    path: "/directionwhitelist",
    name: "directionWhitelistList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "directionwhitelist" */ "./views/pages/direction-whitelist/DirectionWhitelistListPage.vue"
      ),
  },
  {
    path: "/directionwhitelist/:directionWhitelistId",
    name: "editDirectionWhitelist",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "directionwhitelist" */ "./views/pages/direction-whitelist/DirectionWhitelistEditPage.vue"
      ),
  },
  {
    path: "/gds/statistics/daily",
    name: "gdsDailyCounters",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "gds" */ "./views/pages/gds/statistics/daily/GDSDailyCountersListPage.vue"
      ),
  },
  {
    path: "/gds/statistics/directiondaily",
    name: "gdsDirectionDailyCounters",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "gds" */ "./views/pages/gds/statistics/daily/GDSDirectionDailyCountersListPage.vue"
      ),
  },
  {
    path: "/gds/statistics/monthly",
    name: "gdsMonthlyCounters",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "gds" */ "./views/pages/gds/statistics/monthly/GDSMonthlyCountersListPage.vue"
      ),
  },
  {
    path: "/gds/statistics/directionmonthly",
    name: "gdsDirectionMonthlyCounters",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "gds" */ "./views/pages/gds/statistics/monthly/GDSDirectionMonthlyCountersListPage.vue"
      ),
  },
  {
    path: "/bookings/:bookingId/segments/:segmentId/email",
    name: "composeSegmentEmail",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "segmentemail" */ "./views/pages/segment-email/ComposeSegmentEmailPage.vue"
      ),
  },
  {
    path: "/notifications",
    name: "orderNotifications",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "./views/pages/order-notifications/OrderNotificationsListPage.vue"
      ),
  },
  {
    path: "/promocodes",
    name: "promoCodesList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "./views/pages/promocodes/PromoCodesListPage.vue"
      ),
  },
  {
    path: "/agents",
    name: "agentsList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "agents" */ "./views/pages/agents/AgentsListPage.vue"
      ),
  },
  {
    path: "/waitlist-prices",
    name: "waitListPricesList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "waitlist" */ "./views/pages/waitlist/WaitListPricesListPage.vue"
      ),
  },
  {
    path: "/currencies",
    name: "currenciesList",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "currencies" */ "./views/pages/currencies/CurrenciesListPage.vue"
      ),
  },
  {
    path: "/aeroexpress/tickets",
    name: "aeroExpressTickets",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "aeroexpress" */ "./views/pages/aeroexpress/AeroExpressTicketsListPage.vue"
      ),
  },
  {
    path: "/configs/biblioGlobus",
    name: "biblioGlobusConfigPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "configs" */ "./views/pages/config/BiblioGlobusConfigPage.vue"
      ),
  },
  {
    path: "/configs/pegas",
    name: "pegasConfigPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "configs" */ "./views/pages/config/PegasConfigPage.vue"
      ),
  },
  {
    path: "/configs/price",
    name: "priceConfigPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "configs" */ "./views/pages/config/PriceConfigPage.vue"
      ),
  },

  //TOURS
  {
    path: "/tours/orders",
    name: "tourOrdersListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/orders/TourOrdersListPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/orders/:orderUuid",
    name: "tourOrderDetailsPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/orders/TourOrderDetailsPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/packages",
    name: "tourPackagesListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/packages/TourPackagesListPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/references/hotels",
    name: "partnerHotelReferencesListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/references/PartnerHotelReferencesListPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/references/boardbases",
    name: "partnerBoardBasisReferencesListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/references/PartnerBoardBasesReferencesListPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/references/resorts",
    name: "partnerResortReferencesListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/references/PartnerResortReferencesListPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/references/partnertour",
    name: "partnerTourReferencesListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/references/PartnerTourReferencesListPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/hotels",
    name: "hotelsListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/hotels/HotelsListPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/hotels/:hotelId",
    name: "hotelDetailsPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/hotels/HotelDetailsPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/rooms/:roomId",
    name: "roomDetailsPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/rooms/RoomDetailsPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/resorts",
    name: "resortsListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/resorts/ResortsListPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  {
    path: "/tours/flightsurcharges",
    name: "flightSurchargesListPage",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "tours" */ "./views/pages/tours/flightsurcharges/FlightSurchargesListPage.vue"
      ),
    meta: {
      isTours: true,
    },
  },
  ////
  {
    path: "/",
    name: "index",
    beforeEnter: authGuard,
    component: () =>
      import(/* webpackChunkName: "index" */ "./views/Index.vue"),
  },
  {
    path: "*",
    redirect: {
      name: "index",
    },
  },
];

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
  routes: routes,
});
