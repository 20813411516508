export default class AdminUser {
    private readonly _token: string;
    private readonly _email: string;
    private readonly _roles: string[];

    constructor(token: string, email: string, roles: string[]) {
        this._token = token;
        this._email = email;
        this._roles = roles;
    }

    get token(): string {
        return this._token;
    }

    get email(): string {
        return this._email;
    }

    get roles(): string[] {
        return this._roles;
    }
}