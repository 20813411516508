<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    :color="color"
    dark
    dense
  >
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-toolbar-title style="width: 180px" class="ml-0 px-4">
      <span class="hidden-sm-and-down">Nebo.Travel</span>
    </v-toolbar-title>
    <v-tabs :value="tab" v-if="isAviaPermitted && isToursPermitted">
      <v-tab
        key="avia"
        tab-value="avia"
        v-if="isAviaPermitted"
        @click="onAviaClick"
        ><v-icon left>mdi-airplane</v-icon>Авиабилеты</v-tab
      >
      <v-tab
        key="tours"
        tab-value="tours"
        v-if="isToursPermitted"
        @click="onToursClick"
        ><v-icon left>mdi-palm-tree</v-icon>Туры</v-tab
      >
    </v-tabs>
    <v-spacer></v-spacer>
    <v-btn icon @click="toggleTheme">
      <v-icon large>mdi-theme-light-dark</v-icon>
    </v-btn>
    <v-btn
      icon
      :to="{ name: 'orderNotifications' }"
      v-if="isAdmin"
      class="ml-2"
    >
      <v-badge
        :content="notificationsCount"
        :value="notificationsCount"
        color="red"
        overlap
      >
        <v-icon large>mdi-bell</v-icon>
      </v-badge>
    </v-btn>
    <v-btn icon @click="logout" class="ml-2">
      <v-icon>mdi-power</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import orderNotificationService from "@/services/OrderNotificationService";
import authService from "@/services/AuthService";

@Component({
  components: {},
})
export default class Navbar extends Vue {
  notificationsCount: number | null = null;

  drawer = true;

  @Watch("drawer")
  onDrawerChange(val: boolean) {
    this.$emit("drawerChange", val);
  }

  mounted() {
    if (this.isAdmin) {
      orderNotificationService.getNewCount().then((count: number) => {
        this.notificationsCount = count;
      });
    }
  }

  get tab() {
    if (this.isTours) {
      return "tours";
    }

    return "avia";
  }

  onAviaClick() {
    this.$router.push({ name: "ordersList" });
  }

  onToursClick() {
    if (authService.hasRole("ROLE_TOURS_ORDERS")) {
      this.$router.push({ name: "tourOrdersListPage" });
      return;
    }
    if (authService.hasRole("ROLE_TOURS_HOTELS_LIST")) {
      this.$router.push({ name: "hotelsListPage" });
      return;
    }
  }

  get email() {
    return this.$store.getters["auth/currentUserEmail"] || "";
  }

  get isAdmin(): boolean {
    return authService.hasRole("ROLE_ADMIN");
  }

  get isAviaPermitted(): boolean {
    return authService.hasRole("ROLE_AVIA");
  }

  get isToursPermitted(): boolean {
    return authService.hasRole("ROLE_TOURS");
  }

  public logout() {
    this.$store.dispatch("auth/logout");
    this.$router.push("/login");
  }

  async toggleTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  get isTours() {
    return !!this.$route.meta.isTours;
  }

  get color() {
    if (this.isTours) {
      return "purple darken-3";
    }

    return "blue darken-3";
  }
}
</script>
