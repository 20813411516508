<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
    <v-snackbar/>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import DefaultLayout from './views/layout/DefaultLayout.vue';
import LoginLayout from './views/layout/LoginLayout.vue';
import {MetaInfo} from "vue-meta";

@Component({
  components: {
    DefaultLayout,
    LoginLayout,
  },
  metaInfo(): MetaInfo {
    return {
      title: 'NEBO.TRAVEL',
      titleTemplate: '%s | Админка'
    }
  }
})

export default class App extends Vue {
  get layout() {
    return this.$route.meta.layout || 'DefaultLayout'
  }
}
</script>