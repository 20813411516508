import { GetterTree } from 'vuex';
import { AuthState } from '@/store/auth/types';
import { RootState } from '@/store/types';
import AdminUser from "@/models/AdminUser";

export const getters: GetterTree<AuthState, RootState> = {
    currentUser(state): AdminUser | null {
        return state.user;
    },
    currentUserEmail(state): string {
        return (state.user && state.user.email) || '';
    },
    isAuthorized(state): boolean {
        return !!state.user;
    },
};